<template>
  <q-dialog
    @update:model-value="this.onUpdate"
    :persistent="!!this.modalData?.data?.persistent"
    v-model="show"
    @escape-key="() => closePopup(this.modalData.type)"
    :allow-focus-outside="false"
    class="modal__dialog"
  >
    <q-card
      :class="['modal__wrapper', {modal__wrapper_borderless: !!modalData.data?.borderless}]"
      ref="componentRef"
    >
      <q-card-section
        class="row items-center q-pb-none justify-between modal__header"
      >
        <div class="text-h6 modal__title">
          {{ modalData.data?.title || "" }}
        </div>
        <q-space />
        <q-btn
          :class="{'text-red': !!modalData.data?.borderless}"
          @click="() => closePopup(this.modalData.type)"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>
      <component
        :is="modalData.type"
        :data="modalData.data"
        :closeModal="closePopup"
      />
    </q-card>
  </q-dialog>
</template>
<script>
import {appActions} from "@/services/store/modules/app_module/app_actions.js";
import ConfirmModal from "@/components/modal/modals/modal-types/ConfirmModal.vue";
import AddFinancePayment from "@/components/modal/modals/AddFinancePayment";
import CreateAdditionalAgreement from "@/components/modal/modals/create-additional-agreement";
import ChangePaymentStatus from "@/components/modal/modals/ChangePaymentStatus";
import ChangeMaterialStatus from "@/components/modal/modals/ChangeMaterialStatus";
import RemontTimelineStep from "@/components/modal/modals/remont-timeline-step";
import GradeTimelineStep from "@/components/modal/modals/grade-timeline-step";
import ChangeFinancePayment from "@/components/modal/modals/change-finance-payment";
import ModalText from "@/components/modal/modals/modal-text";
import FinancePaymentDocs from "@/components/modal/modals/finance-payment-docs";
import ReceiveKeysEdit from "@/components/modal/modals/receive-keys-edit";
import RemontProjectEdit from "@/components/modal/modals/remont-project-edit";
import CheckListPhotos from "@/components/modal/modals/check-list-photos";
import ParticipantsReplacementHistory from "@/components/modal/modals/participants-replacement-history";
import ChangeRemontResponsible from "@/components/modal/modals/change-remont-responsible";
import AddTranche from "@/components/modal/modals/add-tranche";
import AddIncome from "@/components/modal/modals/add-income";
import AddCost from "@/components/modal/modals/add-cost";
import AddStage from "@/components/modal/modals/add-stage";
import AddRemontService from "@/components/modal/modals/add-remont-service";
import AddDoc from "@/components/modal/modals/add-doc";
import AddRoom from "@/components/modal/modals/add-room";
import AddNoticeTemplate from "@/components/modal/modals/add-notice-template";
import AddHandbookProvider from "@/components/modal/modals/add-handbook-provider";
import AddHandbookContractor from "@/components/modal/modals/add-handbook-contractor"
import AddHandbookTechprojectDeveloper from "@/components/modal/modals/add-handbook-techproject-developer"
import EditChatMessage from "@/components/modal/modals/edit-chat-message";
import EditHandbookWorkset from "@/components/modal/modals/edit-handbook-workset";
import AddProvidersAgreement from "@/components/modal/modals/add-providers-agreement";
import AddHandbookDocument from "@/components/modal/modals/add-handbook-document";
import AddAgreement from "@/components/modal/modals/add-agreement";
import AddGradeAction from "@/components/modal/modals/add-grade-action";
import AddStepCondition from "@/components/modal/modals/add-step-condition";
import AddTimelineStep from "@/components/modal/modals/add-timeline-step";
import AddGradeTimelineStep from "@/components/modal/modals/add-grade-timeline-step";
import AddStepActivity from "@/components/modal/modals/add-step-activity";
import ChatGallery from "@/components/modal/modals/chat-gallery";
import AddMobileAppVersion from "@/components/modal/modals/add-mobile-app-version";
import ExecutiveReportComments from "@/components/modal/modals/executive-report-comments";
import ExecutiveReportDeadlineView from "@/components/modal/modals/executive-report-deadline-view";
import ExecutiveReportSetDeadline from "@/components/modal/modals/executive-report-set-deadline";
import ExecutiveReportEmployeeSet from "@/components/modal/modals/executive-report-employee-set";
import ExecutiveReportNps from "@/components/modal/modals/executive-report-nps";
import ExecutiveReportAnalyze from "@/components/modal/modals/executive-report-analyze";
import ExecutiveReportWarrantyAccept from "@/components/modal/modals/executive-report-warranty-accept";
import ExecutiveReportFlatList from "@/components/modal/modals/executive-report-flat-list";
import ExecutiveReportOkk from "@/components/modal/modals/executive-report-okk";
import ChangeRemontGrade from "@/components/modal/modals/change-remont-grade";
import NestedGradeTimelineSteps from "@/components/modal/modals/nested-grade-timeline-steps";
import ApartmentOwnersHistory from "@/components/modal/modals/apartment-owners-history";
import ChangeWarrantyStatus from "@/components/modal/modals/change-warranty-status";
import ChangeWarrantyEmployee from "@/components/modal/modals/change-warranty-employee";
import WarrantyAct from "@/components/modal/modals/warranty-act";
import ChangeWarrantyRequest from "@/components/modal/modals/change-warranty-request";
import ChangeDSSum from "@/components/modal/modals/change-ds-sum";
import AddWorksetPrice from "@/components/modal/modals/add-workset-price";
import AddHiddenWorkset from "@/components/modal/modals/add-hidden-workset";
import AddHandbookContractorWorkset from "@/components/modal/modals/add-handbook-contractor-workset";
import TextConstructorWorks from "@/components/modal/modals/text-constructor-works";
import TextConstructorMaterials from "@/components/modal/modals/text-constructor-materials";
import TextConstructorInfo from "@/components/modal/modals/text-constructor-info";
import TextConstructorMaterialPriceChange from "@/components/modal/modals/text-constructor-material-price-change";
import ProviderRequestsContractorForm from "@/components/modal/modals/provider-requests-contractor-form";
import ProviderRequestsProviderForm from "@/components/modal/modals/provider-requests-provider-form";
import ProviderRequestsShippingDateHistory from "@/components/modal/modals/provider-requests-shipping-date-history";
import ProviderRequestsShippingDateChange from "@/components/modal/modals/provider-requests-shipping-date-change";

export default {
  name: "modal-dialog",
  components: {
    GradeTimelineStep,
    ConfirmModal,
    CreateAdditionalAgreement,
    ChangeWarrantyStatus,
    EditChatMessage,
    EditHandbookWorkset,
    CheckListPhotos,
    ParticipantsReplacementHistory,
    ModalText,
    FinancePaymentDocs,
    ReceiveKeysEdit,
    RemontProjectEdit,
    RemontTimelineStep,
    ChangeRemontResponsible,
    ChangeMaterialStatus,
    ChangeFinancePayment,
    ChangePaymentStatus,
    AddFinancePayment,
    AddTranche,
    ApartmentOwnersHistory,
    AddCost,
    AddStage,
    AddRemontService,
    AddDoc,
    AddRoom,
    AddWorksetPrice,
    AddHiddenWorkset,
    AddHandbookContractorWorkset,
    AddStepCondition,
    AddGradeAction,
    AddStepActivity,
    AddIncome,
    AddNoticeTemplate,
    AddHandbookProvider,
    AddHandbookContractor,
    AddHandbookTechprojectDeveloper,
    AddProvidersAgreement,
    AddHandbookDocument,
    AddAgreement,
    ChatGallery,
    AddMobileAppVersion,
    ExecutiveReportComments,
    ExecutiveReportDeadlineView,
    ExecutiveReportSetDeadline,
    ExecutiveReportEmployeeSet,
    ExecutiveReportNps,
    ExecutiveReportAnalyze,
    ExecutiveReportWarrantyAccept,
    ExecutiveReportFlatList,
    ExecutiveReportOkk,
    ChangeRemontGrade,
    AddTimelineStep,
    AddGradeTimelineStep,
    NestedGradeTimelineSteps,
    ChangeWarrantyEmployee,
    WarrantyAct,
    ChangeWarrantyRequest,
    ChangeDSSum,
    TextConstructorWorks,
    TextConstructorMaterials,
    TextConstructorInfo,
    TextConstructorMaterialPriceChange,
    ProviderRequestsContractorForm,
    ProviderRequestsProviderForm,
    ProviderRequestsShippingDateHistory,
    ProviderRequestsShippingDateChange,
  },
  computed: {
    show() {
      return this.$store.state.app.modal.show;
    },
    modalData() {
      return this.$store.state.app.modal;
    },
  },
  methods: {
    closePopup(type) {
      const currentType = this.modalData.type
      const onClose = this.modalData?.data?.onClose
      if (type === currentType || type === undefined) {
        this.$store.commit(appActions.closeModal());
      }
      if (onClose) onClose()
    },
    onUpdate() {
      if (this.modalData?.data?.close !== false)
        this.closePopup(this.modalData.type);
    },
  }
};
</script>

<style scoped lang="scss">
.modal {
  &__wrapper {
    padding: 25px;
    max-width: initial !important;

    &_borderless {
      background: none;
      box-shadow: none;
      max-height: initial !important;
    }
  }

  &__header {
    gap: 10px;
    flex-wrap: nowrap;
    padding: 0;
  }

  &__title {
    font-size: 17px;
    line-height: 22px;
  }

  @media (max-width: 576px) {
    &__wrapper {
      padding: 15px;
    }
    &__dialog {
      padding: 15px;
    }
  }

  @media (max-width: 370px) {
    &__wrapper {
      padding: 10px;
    }
    &__dialog {
      padding: 5px;
    }
  }
}
</style>
