import { providerRequestsAPI } from "@/views/ProviderRequests/api";
import { financePaymentsFiltersAPI } from "@/views/FinancePayments/FinancePaymentsFilters/api";

export const getProviderRequests = async (filters) => {
  try {
    const res = await providerRequestsAPI.getList(filters);
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestItem = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestItem(
      provider_request_id
    );
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestContractors = async () => {
  try {
    const res = await providerRequestsAPI.getProviderRequestContractors();
    return res?.data;
  } catch (e) {}
};

export const getProviderRequestShippingDateHistory = async (
  provider_request_id
) => {
  try {
    const res = await providerRequestsAPI.getProviderRequestShippingDateHistory(
      provider_request_id
    );
    return res?.data;
  } catch (e) {}
};

export const updateProviderRequestContractor = async (body) => {
  try {
    const res = await providerRequestsAPI.updateProviderRequestContractor(body);
    return res?.data;
  } catch (e) {}
};

export const changeProviderRequestTypeCommon = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestTypeCommon(body);
    return res?.data;
  } catch (e) {}
};
export const changeProviderRequestProviderCommon = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestProviderCommon(
      body
    );
    return res?.data;
  } catch (e) {}
};
export const setProviderRequestBuh = async (body) => {
  try {
    const res = await providerRequestsAPI.setProviderRequestBuh(body);
    return res?.data;
  } catch (e) {}
};

export const getCommonProvidersData = async () => {
  try {
    const res = await financePaymentsFiltersAPI.getProviders();
    return res?.providers;
  } catch (e) {}
};
export const changeProviderRequestDateShipping = async (body) => {
  try {
    const res = await providerRequestsAPI.changeProviderRequestDateShipping(
      body
    );
    return res?.data;
  } catch (e) {}
};

export const getDocsTableData = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getDocsTableData(provider_request_id);
    return res?.data;
  } catch (e) {}
};
export const getDocsClientData = async (remont_id) => {
  try {
    const res = await providerRequestsAPI.getDocsClientData(remont_id);

  console.log("ClientData res and remont_id", res, remont_id);
  
    return res?.data;
  } catch (e) {}
};
export const getDocsProviderInfo = async (provider_request_id) => {
  try {
    const res = await providerRequestsAPI.getDocsProviderInfo(provider_request_id);

  console.log("ProviderInfo res and provider_request_id", res, provider_request_id);
  
    return res?.data;
  } catch (e) {}
};

export const ProviderRequestsTableColumns = [
  {
    name: "request_id",
    label: "ID заявки",
    field: "request_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "remont_id",
    label: "ID ремонта",
    field: "remont_id",
    sortable: true,
    headerStyle: "width: 80px",
    style: "width: 100px",
    align: "center",
  },
  {
    name: "provider",
    label: "Поставщик",
    field: "provider",
    sortable: true,
    align: "center",
  },
  {
    name: "contractor",
    label: "Подрядная организация",
    field: "contractor",
    align: "center",
  },
  {
    name: "address",
    label: "Адрес доставки",
    field: "address",
    align: "center",
  },
  {
    name: "info",
    label: "Информация",
    field: "info",
    align: "center",
  },
  {
    name: "status",
    label: "Статус",
    field: "status",
    align: "center",
  },
  {
    name: "dtbn",
    label: "",
    field: "dtbn",
    align: "center",
  },
  {
    name: "dcs",
    label: "",
    field: "dcs",
    align: "center",
  },
];

export const groupActions = [
  { label: "Сменить тип заявки общий SR_PR", value: 0 }, //Просто кнопка /provider_request/provider_request_change_data_base/ post
  { label: "Сменить поставщика общий SR_PR", value: 1 }, // МОДАЛКА /provider_request/provider_request_change_data_base/ post
  { label: "Сменить дату отгрузки общий SR_PR", value: 2 }, // МОДАЛКА /provider_request/upd_provider_request_date_shipping/ post
  { label: "Отправить накладные В 1C SR_PR", value: 3 }, // Просто кнопка /provider_request/provider_request_set_carried_buh/ post
  { label: "Сформировать накладную", value: 4 }, //
];

export const DocEditTableColumns = [
  {
    name: "request_id",
    label: "Наименование",
    field: "request_id",
    sortable: true,
    align: "center",
  },
  {
    name: "remont_id",
    label: "Тип",
    field: "remont_id",
    sortable: true,
    align: "center",
  },
  {
    name: "provider",
    label: "Номер счета",
    field: "provider",
    sortable: true,
    align: "center",
  },
  {
    name: "contractor",
    label: "Дата",
    field: "contractor",
    align: "center",
  },
  {
    name: "address",
    label: "Сумма",
    field: "address",
    align: "center",
  },
  {
    name: "info",
    label: "Дата платежки с банка",
    field: "info",
    align: "center",
  },
  {
    name: "status",
    label: "Сумма платежки с банка",
    field: "status",
    align: "center",
  },
  {
    name: "dltbn",
    label: "",
    field: "dltbn",
    align: "center",
  },
];
