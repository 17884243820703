import { providerRequestsFiltersAPI } from "./api";

export const getFiltersMetaData = async () => {
  // ожидает все данные, и если одну из них не получает, то остальное ничего не грузит:
  return await Promise.all([
    providerRequestsFiltersAPI.getProviders(),
    providerRequestsFiltersAPI.getResidents(),
    providerRequestsFiltersAPI.getMaterialTypes(),
    providerRequestsFiltersAPI.getRequestTypes(), 
    providerRequestsFiltersAPI.getStatus(),
    providerRequestsFiltersAPI.getDaysCnt(), 
  ]).catch(() => {
    console.log('filtersMetadata error');    
    return null;
  });
};

export const getMaterials = async () => {
  try {
    const res = await providerRequestsFiltersAPI.getMaterials();
    return res?.data;
  } catch (e) {}
};


export const remontStatuses = [ //remont_status_id
    { label: "Все", value: 0 },
    { label: "Начат", value: 1 },
    { label: "Гарантия", value: 4 },
    { label: "Закончен", value: 2 },
  ];
export const isDirty = [ //is_dirty
    { label: "Все", value: -1 },
    { label: "Чистовой", value: 0 }, // clearable false на value: 0 по дефолту
    { label: "Черновой", value: 1 },
  ];
export const requestType = [ //request_type
    { label: "Все", value: 0 },
    { label: "От поставщика", value: 1 },
    { label: "От склада", value: 2 },
  ];
export const invoiceType = [ //invoice_type
    { label: "Выберите из списка", value: 0 }, // Мб убрать тк нет смысла у нас на фронте, или по дефолту чтобы стояло 0
    { label: "Инвойс для SR", value: 1 },
    { label: "Инвойс для Партнера", value: 2 },
  ];
export const isInvoiceExists = [ //is_exist_invoice
    { label: "Выберите из списка", value: 0 }, // Мб убрать тк нет смысла у нас на фронте, или по дефолту чтобы стояло 0
    { label: "Есть накладная", value: 1 },
    { label: "Нет накладной", value: 2 },
  ];
export const isInvoiceCarried = [ // is_invoice_carried
    { label: "Выберите из списка", value: 0 },
    { label: "Проведен", value: 1 },
    { label: "Не проведен", value: 2 },
  ];
