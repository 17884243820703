<template>
  <ProviderDocEdit
      v-if="isEditingDoc"
      :providerRequestId="selectedProviderRequestId"
      :remontId="selectedRemontId"
      @close="closeDocEdit"
    />
  <div v-else>
    <FiltersWrapper>
      <div ref="header">
        <ProviderRequestsFilters :values="this.currentFilters" :getData="this.getData"
          @filter_change="this.onFiltersChange" />
      </div>
    </FiltersWrapper>

    <CustomTable title="Заявки поставщикам" :rowsData="this.data" :columns="ProviderRequestsTableColumns"
      :stickyHeader="false" :loading="loading" :custom-body="true" :small="true" :checkbox="true"
      :checkbox-value="this.allSelected" :on-all-check-click="onAllCheckClick">

      <template #top-right>
        <CustomSelect :options="this.groupActions" :label="computedLabel" optionValueKey="value" optionLabelKey="label"
          value="Групповые действия" :clearable="false" :fullWidth="true" @change="this.onGroupActionChange" />
      </template>

      <template #customBody="props">
        <q-tr>
          <custom-table-cell>
            <div class="check__wrapper">
              <CustomCheckbox :value="!!this.selectedItems[props.row.provider_request_id]"
                @change="(value) => onCheckboxChange(props.row.provider_request_id, value)" />
            </div>

          </custom-table-cell>
          <custom-table-cell align="right">
            <span>
              {{ props.row.provider_request_id }}
              <q-tooltip>
                ID заявки
              </q-tooltip>
            </span>
          </custom-table-cell>
          <custom-table-cell align="right">
            <span>
              <Clickable router-link :href="`/requests/${props.row.remont_id}?tab=remontDocs`">
                {{ props.row.remont_id || '' }}
              </Clickable>
              <q-tooltip>
                ID ремонта
              </q-tooltip>
            </span>
          </custom-table-cell>
          <custom-table-cell>
            <span v-html="`${props.row.provider_name}`"></span>
            <div class="hr hr-0"></div>
            <span>Заявка №{{ props.row.provider_request_id }} от {{ props.row.date_send }}</span>
            <div class="hr hr-0"></div>
            <div>
              <!-- Гарантийная заявка -->
              <div v-if="props.row.is_grt === 1" class="badge badge-danger">
                Гарантийная заявка
              </div>

              <!-- Дополнительная заявка -->
              <div v-if="props.row.provider_request_additional_reason_id > 0" class="badge badge-success"
                :title="props.row.additional_reason_name">
                Доп. заявка
              </div>

              <!-- Со склада или от поставщика -->
              <div v-if="props.row.is_from_warehouse === 1" class="badge badge-info">
                Со склада {{ props.row.warehouse_name }}
              </div>
              <div v-else rounded class="badge badge-purple">
                От поставщика
              </div>

              <!-- Заказчик подрядчик -->
              <div v-if="props.row.provider_request_type_id === 2 && props.row.contractor_id !== null"
                class="badge badge-info badge-info-hover" :title="props.row.pay_contractor_name"
                @click="showModalProviderRequestContractorForm(props.row)">
                Заказчик подрядчик
              </div>

              <!-- Заказчик МП -->
              <div v-if="props.row.provider_request_type_id === 2 && props.row.techproject_developer_id !== null"
                class="badge badge-info" :title="props.row.pay_contractor_name">
                Заказчик МП
              </div>
            </div>
            <div class="hr hr-0"></div>
            <span> Дата ближайшей планируемой отгрузки:
              {{ props.row.date_shipping_plan_date }}
              <q-btn size="md" class="history-btn" round flat color="primary"
                @click="showModalProviderRequestShippingDateHistory(props.row.provider_request_id)">
                <i class="fa-solid fa-clock-rotate-left"></i>
              </q-btn>
            </span>
          </custom-table-cell>
          <custom-table-cell>
            <div><b>Подрядчик:</b> {{ props.row.contractor_name }}</div>
            <div><b>Прораб:</b> {{ props.row.manager_project }}</div>
            <div><b>Инициатор:</b> {{ props.row.initiator }}</div>
          </custom-table-cell>
          <custom-table-cell>
            <div><b>ЖК:</b> {{ props.row.resident_name }}</div>
            <div><b>Адрес ЖК:</b> {{ props.row.resident_address }}</div>
            <div><b>Кв:</b> {{ props.row.flat_num }}</div>
            <div><b>Город:</b> {{ props.row.city_name }}</div>
            <div><b>Контакты:</b> {{ props.row.delivery_contact }}</div> <!--  ??????? delivery_contact?-->
          </custom-table-cell>
          <custom-table-cell>
            <div class="backgroundBlue">
              <span v-if="props.row.invoice_info">
                <span v-html="props.row.invoice_info"></span>
              </span>
            </div>
          </custom-table-cell>
          <custom-table-cell>
            <span v-html="`${props.row.statuses}`"></span>
          </custom-table-cell>
          <custom-table-cell>
            <q-btn @click="() => this.toggleCollapseAndGetProviderRequestItem(props.row.provider_request_id)"
              :color="collapsedItems[props.row.provider_request_id] ? 'red' : 'green'" size="md" class="toggle-btn"
              rounded flat>
              <i class="fa-solid fa-angle-down" :class="{ rotated: collapsedItems[props.row.provider_request_id] }"></i>
            </q-btn>
          </custom-table-cell>
            <custom-table-cell>
              <CustomIcon icon="file" color="green" @click="openDocEdit(props.row.provider_request_id, props.row.remont_id)"></CustomIcon>
              <q-tooltip>Документы</q-tooltip>
            </custom-table-cell>
        </q-tr>

        <transition name="fade-collapse">
          <q-tr v-if="collapsedItems[props.row.provider_request_id]">
            <q-td colspan="20">
              <CollapseBlock
                :onChange="() => this.toggleCollapseAndGetProviderRequestItem(props.row.provider_request_id)"
                :collapsedProps="!this.collapsedItems[props.row.provider_request_id]" :controlMode="true" hideExpand>
                <CustomTable :title="`Материалы заявки №${props.row.provider_request_id}`" :rowsData="this.itemData"
                  :small="true" :customHeader="true" :custom-body="true" :dense="true" flat :hideTopTitle="true"
                  :loading="innerTableLoading">
                  <template #customHeader="innerProps" сlass="innerTableHeader sticky">
                    <q-tr class="innerTableHeader">
                      <q-th rowspan="2" сlass="innerTableHeader">
                        check
                      </q-th>
                      <q-th align="center" colspan="11" сlass="innerTableHeader">
                        Заказы
                      </q-th>
                      <q-th align="center">
                        btn
                      </q-th>
                      <q-th rowspan="2">
                        check
                      </q-th>
                      <q-th align="center" colspan="8">
                        Факт
                      </q-th>
                    </q-tr>
                    <q-tr class="sticky">
                      <q-th align="center">
                        ID
                      </q-th>
                      <q-th align="center">
                        Наименование
                      </q-th>
                      <q-th align="center">
                        Дата отгрузки
                      </q-th>
                      <q-th align="center" style="background-color: #C8E6C9;">
                        Кол-во в ед.продаж
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.отпуска
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.расх.
                      </q-th>
                      <q-th align="center">
                        В мин. кол-во
                      </q-th>
                      <q-th align="center">
                        Кол-во в упак.
                      </q-th>
                      <q-th align="center">
                        Цена
                      </q-th>
                      <q-th align="center">
                        Сумма
                      </q-th>
                      <q-th align="center">
                        Номер накладной
                      </q-th>
                      <q-th align="center">
                        Статус
                      </q-th>


                      <q-th align="center">
                        ID
                      </q-th>
                      <q-th align="center">
                        Наименование
                      </q-th>
                      <q-th align="center">
                        Кол-во в ед.продаж
                      </q-th>
                      <q-th align="center">
                        Цена
                      </q-th>
                      <q-th align="center">
                        Сумма
                      </q-th>
                      <q-th align="center">
                        +
                        <!-- TODO будет кнопка -->
                      </q-th>
                    </q-tr>
                  </template>

                  <template #customBody="innerBodyProps">
                    <q-tr>
                      <custom-table-cell align="center">
                        check
                      </custom-table-cell>
                      <custom-table-cell align="center">
                        {{ innerBodyProps.row.material_id }}
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.material_name}`"></span>
                        <div class="hr hr-0"></div>
                        <span v-html="`${innerBodyProps.row.material_name_extra}`" style="color: brown;"></span>
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.date_shipping}`"></span>
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.material_cnt }}
                        {{ innerBodyProps.row.sell_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.delivery_cnt }}
                        {{ innerBodyProps.row.delivery_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.consumption_cnt }}
                        {{ innerBodyProps.row.consume_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.qty_atom }}
                        {{ innerBodyProps.row.atom_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.qty_pack }}
                        {{ innerBodyProps.row.pack_unit_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.price" />
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.price_amount" />
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.release_text}`"></span>
                      </custom-table-cell>
                      <custom-table-cell>
                        {{ innerBodyProps.row.provider_request_status_name }}
                      </custom-table-cell>
                      <custom-table-cell>
                        check
                      </custom-table-cell>
                      <custom-table-cell align="center">
                        {{ innerBodyProps.row.invoice_material_id }}
                      </custom-table-cell>
                      <custom-table-cell>
                        <span v-html="`${innerBodyProps.row.invoice_material_name}`"></span>
                        <div class="hr hr-0"></div>
                        <span title="Наименование у поставщика" class="text-danger"
                          v-html="`${innerBodyProps.row.invoice_material_name_extra}`"></span>
                        <div class="hr hr-0"></div>
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        {{ innerBodyProps.row.invoice_material_cnt }}
                        {{ innerBodyProps.row.invoice_unit_short_name }}
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.invoice_material_price" />
                      </custom-table-cell>
                      <custom-table-cell align="right">
                        <Amount :value="innerBodyProps.row.invoice_price_amount" />
                      </custom-table-cell>
                      <custom-table-cell>
                        <!-- TODO кнопка удаления с подтверждением действия -->
                        dlt
                      </custom-table-cell>
                    </q-tr>
                  </template>

                </CustomTable>
              </CollapseBlock>
            </q-td>
          </q-tr>
        </transition>

      </template>
    </CustomTable>
  </div>
</template>

<script>
import FiltersWrapper from "@/components/common/FiltersWrapper";
import CustomTable from "@/components/common/CustomTable";
import CustomIcon from "@/components/common/CustomIcon.vue";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomSelect from "@/components/form/CustomSelect";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import CollapseBlock from "@/components/common/CollapseBlock";
import { appActions } from "@/services/store/modules/app_module/app_actions";
import { MODAL_NAMES } from "@/constants";
import { useCustomSnackbar } from "@/_helpers/hooks/useCustomSnackbar";
import { ProviderRequestsTableColumns, getProviderRequests, getProviderRequestItem, updateProviderRequestContractor, groupActions, changeProviderRequestTypeCommon, setProviderRequestBuh, changeProviderRequestProviderCommon, changeProviderRequestDateShipping } from "./services";

import ProviderRequestsFilters from "./ProviderRequestsFilters";
import ProviderDocEdit from "./providerDocEdit.vue";

export default {
  name: "ProviderRequests",
  components: {
    CustomTableCell,
    Clickable,
    CustomTable,
    CustomIcon,
    CustomCheckbox,
    CustomSelect,
    FiltersWrapper,
    Amount,
    CollapseBlock,
    ProviderRequestsFilters,
    ProviderDocEdit,
  },
  data() {
    return {
      data: [],
      itemData: [],
      currentFilters: {
        remont_status_id: 0,
        is_dirty: -1,
        request_type: 0,
        invoice_receive_org_type: 0,
        is_exist_invoice: 0,
        is_invoice_carried: 0,
        is_no_invoice: 0, // рассмотеть какой дефолт отправлять
      },
      filters: {
        provider_id: '',
        date_shipping_plan_begin: new Date().toLocaleDateString("ru"),
        date_shipping_plan_end: new Date().toLocaleDateString("ru"),
        provider_request_status_id_arr: [],
      },
      collapsedItems: {},
      loading: false,
      innerTableLoading: false,
      ProviderRequestsTableColumns,
      allSelected: false,
      selectedItems: {},
      groupActions,
      isEditingDoc: false, // Показывает, какой компонент отобразить
      selectedProviderRequestId: null,
      selectedRemontId: null,
    }
  },
  watch: {
    selectedItems(newValue) {
      const checkedLength = this.getSelectedLength(newValue);
      const isAllChecked = this?.data.length === checkedLength;
      if (isAllChecked && !!checkedLength) return (this.allSelected = true);
      if (checkedLength > 0) return (this.allSelected = "");
      this.allSelected = false;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      const res = await getProviderRequests(this.currentFilters);
      this.loading = false;
      if (!res) return;
      this.filters = { ...this.currentFilters }
      this.data = res;
      this.resetSelectedItems();
    },
    onFiltersChange(key, value) {
      if (this.currentFilters[key] === value) return;
      this.currentFilters[key] = value;
    },
    async toggleCollapseAndGetProviderRequestItem(id) {
      this.collapsedItems[id] = !this.collapsedItems[id]
      this.innerTableLoading = true;
      if (!this.collapsedItems[id]) {
        this.innerTableLoading = false;
        return
      } else {
        const res = await getProviderRequestItem(id);
        this.innerTableLoading = false;
        if (!res) return;
        this.itemData = res;
      }
    },
    showModalProviderRequestContractorForm(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_CONTRACTOR_FORM, {
        onSubmit: (body) => this.onUpdateSubmit(body),
        title: 'Изменение подрядчика',
        body: row
      }));
    },
    showModalProviderRequestShippingDateHistory(provider_request_id) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_SHIPPING_DATE_HISTORY, {
        title: 'История изменений даты отгрузки',
        body: { provider_request_id: provider_request_id }
      }));
    },
    showModalProviderForm(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_PROVIDER_FORM, {
        onSubmit: (body) => this.onUpdateProviderSubmit(body),
        title: 'Изменить поставщика в заявке',
        body: row
      }));
    },
    showModalShippingDateChange(row) {
      this.$store.commit(appActions.showModal(MODAL_NAMES.PROVIDER_REQUESTS_SHIPPING_DATE_CHANGE, {
        onSubmit: (body) => this.onUpdateProviderShippingDate(body),
        title: 'Изменить поставщика в заявке',
        body: row
      }));
    },
    async onUpdateSubmit(body) {
      const res = await updateProviderRequestContractor(body)
      if (!res) return
      this.rowsData = res
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateProviderSubmit(body) {
      const res = await changeProviderRequestProviderCommon(body)
      if (!res) return
      this.rowsData = res
      this.getData()
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    async onUpdateProviderShippingDate(body) {
      const res = await changeProviderRequestDateShipping(body)
      if (!res) return
      this.rowsData = res
      this.getData()
      this.showSucceedSnackbar()
      this.$store.commit(appActions.closeModal());
    },
    getSelectedLength(selectedItems) {
      if (selectedItems)
        return Object.keys(selectedItems).filter(
          (item) => !!selectedItems[item]
        ).length;
      return Object.keys(this.selectedItems).filter(
        (item) => !!this.selectedItems[item]
      ).length;
    },
    onAllCheckClick(value) {
      this.allSelected = value;

      // Преобразуем массив this.data в объект
      this.selectedItems = this.data.reduce((acc, item) => {
        acc[item.provider_request_id] = value;
        return acc;
      }, {});
    },
    onCheckboxChange(provider_request_id, value) {
      this.selectedItems = { ...this.selectedItems, [provider_request_id]: value };
    },
    resetSelectedItems() {
      this.selectedItems = {};
    },
    // Групповые действия:
    getSelectedRows() {
      // Фильтруем `this.data`, оставляя только выбранные строки
      return this.data.filter((row) => this.selectedItems[row.provider_request_id]);
    },
    async onGroupActionChange(value) {
      const selectedRows = this.getSelectedRows();

      if (!selectedRows.length) {
        this.$q.notify({
          color: 'warning',
          message: 'Выберите хотя бы одну строку для выполнения действия',
        });
        return;
      }

      const body = {
        data: selectedRows,
      };

      // Вызов функции в зависимости от `value`
      switch (value) {
        case 0: // "Сменить тип заявки общий SR_PR"
          await this.onClickChangeRequestTypeCommon(body);
          break;
        case 1: // "Сменить поставщика общий SR_PR"
          await this.onClickChangeProviderCommon(body);
          break;
        case 2: // "Сменить дату отгрузки общий SR_PR"
          await this.onClickChangeShippingDate(body);
          break;
        case 3: // "Отправить накладные в 1C SR_PR"
          await this.onSendInvoicesTo1C(body);
          break;
        case 4: // "Сформировать накладную"
          await this.onGenerateInvoice(body);
          break;
        default:
          console.error("Неизвестное действие: ", value);
      }
    },

    async onClickChangeRequestTypeCommon(body) {
      console.log('Сменить тип заявки общий SR_PR', body.data);

      // Формируем payload
      const payload = {
        provider_request_id_arr: body.data.map(row => row.provider_request_id),
        toggle_is_from_warehouse: 1,
      };

      // Вызов API
      const res = await changeProviderRequestTypeCommon(payload);
      if (!res) return;

      this.rowsData = res;
      this.getData()
      this.showSucceedSnackbar();
    },
    async onClickChangeProviderCommon(body) {
      // Вызов модалки для смены поставщика
      const payload = {
        provider_request_id_arr: body.data.map(row => row.provider_request_id),
        toggle_is_from_warehouse: 1,
      };
      return this.showModalProviderForm(payload)
    },
    async onClickChangeShippingDate(body) {
      // Вызов модалки для смены даты отгрузки
      const payload = {
        provider_request_id: body.data.map(row => row.provider_request_id),
        toggle_is_from_warehouse: 1,
        // provider_request_id, date_shipping_plan, date_shipping_comment, provider_request_item_id_arr
      };

      return this.showModalShippingDateChange(payload)
    },
    async onSendInvoicesTo1C(body) {
      console.log("Отправить накладные в 1C SR_PR", body);
      // Вызов API для отправки накладных в 1C
      const payload = {
        provider_request_id_arr: body.data.map(row => row.provider_request_id),
        toggle_is_from_warehouse: 1,
      };
      const res = await setProviderRequestBuh(payload);
      if (!res) return;
      this.rowsData = res;
      this.getData()
      this.showSucceedSnackbar();
    },
    async onGenerateInvoice(body) {
      console.log("Сформировать накладную", body);
      // Здесь должен быть вызов API для формирования накладной
    },
    openDocEdit(providerRequestId, remontId) {
      this.selectedProviderRequestId = providerRequestId;
      this.selectedRemontId = remontId;
      this.isEditingDoc = true;
    },
    closeDocEdit() {
      this.isEditingDoc = false;
    },    
  },
  computed: {
    userData() {
      return this.$store.state.app?.userData
    },
  },
  setup() {
    const { showSucceedSnackbar } = useCustomSnackbar();
    return { showSucceedSnackbar };
  },
  computed: {
    computedLabel() {
      // Проверяем, есть ли данные в selectedItems
      const hasSelected = Object.values(this.selectedItems).some((value) => value === true);
      return hasSelected ? 'Групповые действия' : 'Выберите заявки для изменения типа';
    },
  }
}

</script>

<style>
.hr.hr-0 {
  margin: 3px 0 !important;
}

.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;

  &:last-child {
    border: none;
  }
}

.innerTableHeader {
  border: 1px solid rgba(0, 0, 0, 0.12) !important
}

.q-table thead.innerTableHeader,
.q-table tr.innerTableHeader,
.q-table th.innerTableHeader,
.q-table td.innerTableHeader {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.badge {
  padding: 5px 10px;
  border-radius: 20px;
  /* Закругление */
  font-size: 10px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Для эффекта hover */
}

.text-danger {
  color: #dc3545;
}

.badge-danger {
  background-color: #dc3545;
}

.badge-success {
  background-color: #28a745;
}

.badge-info {
  background-color: #17a2b8;
}

.badge-purple {
  background-color: #6f42c1;
}

.badge-info-hover:hover {
  background-color: #138496;
  /* Новый цвет при наведении */
  transform: scale(1.05);
  /* Увеличение при наведении */
  cursor: pointer;
  /* Указывает, что элемент кликабелен */
}

.history-btn {
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Плавные эффекты */
}

.history-btn:hover {
  background-color: #4387e0;
  color: white !important;
  /* Цвет при наведении */
  transform: scale(1.1);
  /* Легкое увеличение кнопки */
  cursor: pointer;
  /* Указатель */
}

.toggle-btn {
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Плавные эффекты */
}

.toggle-btn:hover {
  background-color: #138496;
  color: white;
  /* Цвет при наведении */
  transform: scale(1.1);
  /* Легкое увеличение кнопки */
  cursor: pointer;
  /* Указатель */
}

.toggle-btn i {
  transition: transform 0.2s ease;
  /* Плавное вращение */
}

.toggle-btn i.rotated {
  transform: rotate(180deg);
  /* Вращение вниз */
}

.fade-collapse-enter-active,
.fade-collapse-leave-active {
  transition: all 0.3s ease;
  overflow: hidden;
}

.fade-collapse-enter-from,
.fade-collapse-leave-to {
  max-height: 0;
  opacity: 0;
}

.fade-collapse-enter-to,
.fade-collapse-leave-from {
  opacity: 1;
}

.sticky {
  position: sticky !important;
  z-index: 1;
  top: 0
}

.backgroundBlue {
  /* // Голубой */
  background-color: #E3F2FD;
  border-radius: 8px;
  padding: 5px;
}
</style>
