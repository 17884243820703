import instance from "@/services/api";

export const providerRequestsAPI = {
  async getList(body) {
    return await instance()
      .post("/provider_request/read/", body)
      .then((res) => res?.data);
  },
  async getProviderRequestItem(provider_request_id) {
    return await instance()
      .post("/provider_request/items/", {
        provider_request_id: provider_request_id,
      })
      .then((res) => res?.data);
  },
  async getProviderRequestContractors() {
    return await instance()
      .get("/client_request/common/contractors/read/")
      .then((res) => res?.data);
  },
  async getProviderRequestShippingDateHistory(provider_request_id) {
    return await instance()
      .get(
        `/provider_request/${provider_request_id}/read_provider_shipping_hist`
      )
      .then((res) => res?.data);
  },
  async updateProviderRequestContractor(body) {
    return await instance()
      .post("/provider_request/provider_request_change_contractor/", body)
      .then((res) => res?.data);
  },

  async changeProviderRequestTypeCommon(body) {
    return await instance()
      .post("/provider_request/provider_request_change/type/", body)
      .then((res) => res?.data);
  },
  async changeProviderRequestProviderCommon(body) {
    return await instance()
      .post("/provider_request/provider_request_change/provider/", body)
      .then((res) => res?.data);
  },
  async setProviderRequestBuh(body) {
    return await instance()
      .post("/provider_request/provider_request_set_carried_buh/", body)
      .then((res) => res?.data);
  },
  async changeProviderRequestDateShipping(body) {
    return await instance() 
      .post("/provider_request/upd_provider_request_date_shipping/", body)
      .then((res) => res?.data);
  },  

  async getDocsTableData(provider_request_id) {
    return await instance() 
      .get(`/provider_request/${provider_request_id}/read_provider_request_document/`, )
      .then((res) => res?.data);
  },  
  async getDocsClientData(remont_id) {
    return await instance() 
      .get(`/common/client/${remont_id}/client_info/`, )
      .then((res) => res?.data);
  },  
  async getDocsProviderInfo(provider_request_id) {
    return await instance() 
      .get(`/provider_request/${provider_request_id}/provider_request_info/`, )
      .then((res) => res?.data);
  },  
  async getDocsTypes() {
    return await instance() 
      .get("/common/document_types/pr/", )
      .then((res) => res?.data);
  },  
};