<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onSubmit" class="form">

      <div class="form__item">
        <CustomDate required="true" placeholder="План отгрузки по" :value="this.formData.date_shipping_plan || ''"
          @change_date="(value) => onChange('date_shipping_plan', value)" />
      </div>

      <div class="form__item">
        <CustomInput label="Комментарии" :value="this.formData.date_shipping_comment"
          @clear_input="onChange('date_shipping_comment', '')"
          @change="(value) => onChange('date_shipping_comment', value)" />
      </div>

      <CustomModalFooter :btn-loading="this.loading" />
    </q-form>
  </div>
</template>
<script>

import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomDate from "@/components/form/CustomDate";
import CustomInput from "@/components/form/CustomInput";


export default {
  name: "provider-requests-shipping-date-change",
  components: { CustomModalFooter, CustomDate, CustomInput },
  props: ['data'],
  data() {
    return {
      loading: false,
      formData: {
        provider_request_id_arr: this.data.body?.provider_request_id_arr || '',
        date_shipping_plan: '',
        date_shipping_comment: '',
        provider_request_item_id_arr: [],
      },
      providerOptions: [],
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value
      console.log(`Поле ${key} изменено на:`, value);
    },
    async onSubmit() {
      this.loading = true
      await this.data?.onSubmit(this.formData)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
// TODO решить с размером, чтобы был фикисрованный, но при слишком маленьких/больших размерах изменялся
.form {
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  width: 550px;

  .form__item {
    flex: 1 1 45%;
  }
}
</style>