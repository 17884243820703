<template>
  <span>Документы</span>
  <div class="hr"></div>
  <div class="header">
    <div class="header__column">
      <BlockItem title="ФИО">
        {{ this.data }}
      </BlockItem>
      <BlockItem title="Телефон">
        <!-- {{ this.numberWithCommas(this.data?.work_agreement_sum) }} --> Телефон
      </BlockItem>
      <BlockItem title="Сумма заявки">
        <Amount :value="this.provData.amount" />
        <!-- {{ this.provData.amount }} -->
      </BlockItem>
    </div>
    <div class="header__column">
      <BlockItem title="Ремонт №">
        {{ this.provData.remont_id }}
      </BlockItem>
      <BlockItem title="Поставщик">
        {{ this.provData.provider_name }}
      </BlockItem>
      <BlockItem title="Телефон">
        {{ this.provData.phone }}
      </BlockItem>
    </div>
  </div>

  <q-form @submit="this.onSubmit" class="form">
    <div class="form__item">
      <CustomSelect :options="this.docTypes" label="Тип документа" optionValueKey="days_cnt" optionLabelKey="days_cnt"
        :value="this.docTypes" @change="(value) => onChange('days_cnt', value)" />
    </div>

    <div class="form__item">
      <CustomUploader :value="this.data?.document" :on-change="(value) => this.onChange('document', value)"
        label="Файл" />
    </div>
    <!-- <CustomModalFooter :btn-loading="this.loading" style="justify-content: flex-start;"/> -->
    <div class="footer">
      <q-btn class="footer__btn footer__btn_cancel" :label="cancelBtnText || 'Отмена'" color="red" icon="close"
        @click="onClose" :disable="loading">
      </q-btn>
      <q-btn class="footer__btn footer__btn_submit" :label="submitBtnText || 'Сохранить'" color="secondary" icon="check"
        :disable="loading" :loading="loading" @click="onSubmitSave">
      </q-btn>
    </div>
  </q-form>

  <CustomTable title="Документы" :rowsData="this.tableData" :columns="this.columns" :stickyHeader="false"
    :loading="loading" :custom-body="true" :small="true">
    <template #customBody="props">
      <q-tr>
        <custom-table-cell align="center">
          <Clickable router-link :href="`${props.row.document_url}`">
            {{ props.row.document_name }}
          </Clickable>
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.document_type_name }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.account_num }}
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row.doc_date }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Amount :value="props.row.sum" />
        </custom-table-cell>
        <custom-table-cell align="left">
          {{ props.row }}
        </custom-table-cell>
        <custom-table-cell align="left">
          <Amount :value="props.row.bank_pay_sum" />
        </custom-table-cell>
        <custom-table-cell align="left">
          <CustomIcon icon="trash" :approve-mode="true" :loading="this.loading"
            :approve="() => this.onDeleteSubmit(props.row.provider_request_document_id)" />
        </custom-table-cell>
      </q-tr></template>
  </CustomTable>
</template>

<script>
import BlockItem from "@/components/common/BlockItem";
import CustomSelect from "@/components/form/CustomSelect";
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomUploader from "@/components/form/CustomUploader";
import { getDocsTableData, getDocsClientData, getDocsProviderInfo, DocEditTableColumns } from './services'
import CustomTable from "@/components/common/CustomTable";
import CustomTableCell from "@/components/common/CustomTable/custom-table-cell";
import CustomIcon from "@/components/common/CustomIcon";
import Clickable from "@/components/common/Clickable";
import Amount from "@/components/common/Amount";
import { appActionTypes } from "@/services/store/modules/app_module/app_mutation_types";


export default {
  name: "ProviderDocEdit",
  components: {
    CustomSelect,
    CustomModalFooter,
    BlockItem,
    CustomUploader,
    CustomTableCell,
    CustomIcon,
    Clickable,
    CustomTable,
    Amount,
  },
  props: {
    providerRequestId: {
      type: Number,
      required: true,
    },
    remontId: {
      type: Number,
      required: true,
    },
    cancelBtnText: {
      type: String,
      default: "Отмена",
    },
    submitBtnText: {
      type: String,
      default: "Сохранить",
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      tableData: [],
      provData: [],
      columns: DocEditTableColumns,
      // docTypes: [{ days_cnt: 7 }, { days_cnt: 14 }, { days_cnt: 21 }],
      // selectedDocType: this.data?.document_type_code || '',
    }
  },
  methods: {
    async getDocsClientData() {
      this.loading = true;
      const res = await getDocsClientData(this.remontId);
      this.loading = false;
      if (!res) return;
      this.data = res;
    },
    async getDocsProviderInfo() {
      this.loading = true;
      const res = await getDocsProviderInfo(this.providerRequestId);
      this.loading = false;
      if (!res) return;
      this.provData = res[0];
    },
    async getDocsTableData() {
      this.loading = true;
      const res = await getDocsTableData(this.providerRequestId);
      this.loading = false;
      if (!res) return;
      this.tableData = res;
    },
    onChange(key, value) {
      this[key] = value;
    },
    onSubmitSave() {
      console.log("Saving form data:", this.data);
      // Логика для сохранения данных
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit("close"); // Закрытие после успешного сохранения
      }, 1000);
    },
    onClose() {
      console.log("Closing without saving");
      this.$emit("close"); // Закрытие компонента
    },
  },
  computed: {
    docTypes() {
      return this.$store.state.app.docTypes;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getDocTypes)
    this.getDocsTableData()
    this.getDocsClientData()
    this.getDocsProviderInfo()
  }
}
</script>

<style scoped lang="scss">
.header {
  border-radius: 15px;
  border: 2px solid #E4EAF0;
  background: #FFF;
  padding: 25px 20px 40px 20px;
  margin-top: 25px;
  display: flex;
  gap: 35px;
  position: relative;
  transition: all .3s;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    gap: 20px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
}


.hr {
  display: block;
  height: 0;
  overflow: hidden;
  font-size: 0;
  border-width: 1px 0 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;

  &:last-child {
    border: none;
  }
}

.form {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  gap: 15px;
  margin-top: 35px;
  margin-bottom: 35px;

  &__item {
    &_file {
      padding: 10px 7px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: #1f5ac2;

      &Label {
        margin-bottom: 3px;
        font-size: 13px;
        display: block;
        color: #3b3b3b;
      }
    }
  }

  @media (max-width: 428px) {
    min-width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;

  &__btn {
    &.footer__btn_cancel {
      background-color: #f5f5f5;
      color: #e74c3c;
    }

    &.footer__btn_submit {
      background-color: #3498db;
      color: #fff;
    }

    &.footer__btn_disabled {
      opacity: 0.5;
    }
  }
}
</style>