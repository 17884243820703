<template>
  <div :class="[{ purpose: this.data?.body?.purpose }]">
    <q-form @submit="this.onModalChange" class="form">
      <div class="form__item">
        <CustomInput label="Старая цена" type="number" :controls="true" :value="this.formData.price_old"
          @change_input="(value) => onChange('price_old', value)" required="true" />
      </div>

      <div class="form__item">
        <CustomInput label="Новая цена" type="number" :controls="true" :value="this.formData.price_new"
          @change_input="(value) => onChange('price_new', value)" required="true" />
      </div>

      <div class="form__item">
        <CustomInput label="Разница" type="number" :controls="true" :value="this.formData.price_diff"
          @change_input="(value) => onChange('price_diff', value)" required="true" />
      </div>

      <CustomModalFooter :btn-loading="this.btnLoading" />
    </q-form>
  </div>
</template>

<script>
import CustomModalFooter from "@/components/modal/modals/modal-footer";
import CustomCheckbox from "@/components/form/CustomCheckbox";
import CustomInput from "@/components/form/CustomInput";
import {
  getMaterialPriceData,
} from '@/views/ClientRequests/remontDS/dsCreationTemplates/changeTextConstructor/services'

export default {
  name: "text-constructor-material-price-change",
  components: { CustomCheckbox, CustomModalFooter, CustomInput },
  props: ['data'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      formData: {
        client_request_id: this.data?.body?.client_request_id || '',
        tk_change_id: this.data?.body?.row.tk_change_id || '',
        price_old: '',
        price_new: '',
        price_diff: '',
      },
    }
  },
  methods: {
    onChange(key, value) {
      this.formData[key] = value;
    },
    async getData(client_request_id, tk_change_id) {
      this.loading = true      
      const res = await getMaterialPriceData(client_request_id, tk_change_id);
      console.log('res1:',res);
      if (res) {
        console.log("res2:", res);
// не приходит рес хотя в нетворке есть
        this.formData.price_old = res.price_old || "";
        this.formData.price_new = res.price_new || "";
        this.formData.price_diff = res.price_diff || "";
      }
      this.loading = false
    },
    async onModalChange() {
      this.btnLoading = true
      console.log("formData:", this.formData);
      console.log("client_request_id:", this.data?.body?.client_request_id);
      await this.data?.onModalChange(this.formData)
      this.btnLoading = false
    },
  },
  mounted() {
    this.getData(this.formData.client_request_id, this.formData.tk_change_id)
  }
}
</script>

<style scoped lang="scss">
.form {
  display: grid;
  min-width: 500px;
  grid-template-columns: 12fr;
  gap: 20px;

  @media (max-width: 991px) {
    min-width: 50%;
    max-width: 100%;
    grid-template-columns: 12fr;
  }
}
</style>